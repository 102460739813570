import { createTheme } from '@mui/material/styles'

const MAIN = '#5C9EAD'
const LIGHT = '#FFFFFF'
const DARK = '#326273'
const TEXT = '#333333'
const LIGHT_TEXT = '#666666'
const CONTRAST_TEXT = '#EEEEEE'

// const palette = {
//     primary: {
//         main: MAIN,
//         light: LIGHT,
//         dark: DARK,
//         contrastText: CONTRAST_TEXT,
//         warning: '#cccc99',
//     },
//     secondary: {
//         main: DARK,
//     },
// }

const palette = {
    primary: {
        light: '#c6e6ed',
        main: MAIN,
        dark: DARK,
        contrastText: CONTRAST_TEXT,
    },
    // secondary: {
    //     light: '#ffffff',
    //     main: DARK,
    //     dark: TEXT,
    //     contrastText: '#ff0000',
    // },
    secondary: {
        light: '#ffffff',
        main: TEXT,
        dark: '#cccc99',
        contrastText: CONTRAST_TEXT,
    },
}

const theme = createTheme({
    palette,
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    textDecoration: 'none', // Add underline on hover
                    '&:hover': {
                        textDecoration: 'underline', // Add underline on hover
                    },
                    '&:visited': {
                        color: LIGHT_TEXT,
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.secondary.light,
                    color: palette.secondary.contrastText,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    color: TEXT,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: palette.secondary.light,
                    color: palette.secondary.main,
                },
            },
        },
        MuiSpeedDial: {
            styleOverrides: {
                fab: {
                    backgroundColor: MAIN,
                    color: CONTRAST_TEXT,
                    '&:hover': {
                        backgroundColor: MAIN,
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    '&:hover': {
                        backgroundColor: palette.secondary.contrastText,
                    },
                    '& .Mui-selected': {
                        backgroundColor: palette.primary.main,
                        color: palette.secondary.light,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    lineHeight: '1.5em',
                    margin: '0px',
                    padding: '0px 16px',
                    fontSize: '15pt',
                    // fontWeight: 'bold',
                    backgroundColor: palette.secondary.main,
                    color: palette.secondary.contrastText,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'inherit',
                        color: 'inherit',
                    },
                },
            },
        },
    },
    // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
    typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
    },
})

export default theme
